<template>
  <v-row :id="id">
    <v-col>
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 4 : 1"
          class="px-5 my-5"
          :class="colorIndex % 2 === 0 ? 'item1' : 'item2'"
        >
          <div v-for="(flight, i) in flights" :key="i">
            <v-fade-transition>
              <v-row v-show="animation">
                <v-col>
                  <v-row align="center" class="mt-2 mt-md-0">
                    <v-col cols="12" md="4">
                      <v-row no-gutters>
                        <v-col cols="12" class="text-center text-md-left">
                          <span class="text-h6">
                            {{ flight.company }}
                          </span>
                        </v-col>
                        <v-row justify="center" no-gutters>
                          <v-col
                            cols="4"
                            md="12"
                            class="text-center text-md-left"
                          >
                            Самолет:
                            <span class="light-green--text text--accent-4">
                              {{ flight.plane }}
                            </span>
                          </v-col>
                          <v-col
                            cols="4"
                            md="12"
                            class="text-center text-md-left"
                          >
                            Рейс:
                            <span class="light-green--text text--accent-4">
                              {{ flight.num }}
                            </span>
                          </v-col>
                          <v-col
                            cols="4"
                            md="12"
                            class="text-center text-md-left"
                          >
                            Возвратный билет:
                            <span class="light-green--text text--accent-4"
                              >{{ flight.IsRefundable ? "Да" : "Нет" }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-col>

                    <v-col cols="12" class="d-block d-md-none text-center pb-0">
                      <v-chip
                        color="light-green accent-4"
                        text-color="white"
                        class="mb-1"
                        style="font-weight: bold; font-size: 15px"
                      >
                        {{ flight.depCity }}&nbsp;&nbsp;-&nbsp;&nbsp;{{
                          flight.arrCity
                        }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" class="d-block d-md-none text-center pt-1">
                      <v-chip large>
                        <v-icon class="mr-1">mdi-clock-time-ten-outline</v-icon>
                        <span>{{ flight.flyingTime | flyingTime }}</span>
                      </v-chip>
                    </v-col>

                    <v-col md="3" class="text-center">
                      <v-icon large>la-plane-departure</v-icon>

                      <div class="text-h5 text-md-h4">
                        {{ flight.depTime }}
                        <span class="text-body-1">{{ flight.depDate }}</span>
                      </div>

                      <v-chip class="d-none d-md-block">
                        <v-icon class="mr-1">mdi-map-marker</v-icon>
                        {{ flight.depCity }}
                      </v-chip>
                      <div class="d-none d-md-block">{{ flight.depAir }}</div>
                    </v-col>

                    <v-col class="d-none d-sm-block d-md-none">
                      <v-row justify="center" align="center">
                        <arrow></arrow>
                      </v-row>
                    </v-col>

                    <v-col md="2" class="text-center d-none d-md-block">
                      <div class="mb-3 mb-md-1">
                        {{ flight.flyingTime | flyingTime }}
                      </div>

                      <v-row justify="center" align="center">
                        <arrow></arrow>
                      </v-row>

                      <div
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="light-green--text text--accent-4 mt-1"
                      >
                        <div class="mt-1">
                          <span
                            v-if="!flight.stopsNum"
                            class="light-green--text text--accent-4"
                          >
                            Прямой
                          </span>
                          <span v-else class="red--text">
                            {{
                              flight.stopsNum +
                              " " +
                              stopsTitle(flight.stopsNum)
                            }}
                          </span>
                        </div>
                      </div>
                    </v-col>

                    <v-col md="3" class="text-center">
                      <v-icon large>la-plane-arrival</v-icon>

                      <div class="text-h5 text-md-h4">
                        {{ flight.arrTime }}
                        <span class="text-body-1">{{ flight.arrDate }}</span>
                      </div>

                      <v-chip class="d-none d-md-block">
                        <v-icon class="mr-1">mdi-map-marker</v-icon>
                        {{ flight.arrCity }}
                      </v-chip>
                      <div class="d-none d-md-block">{{ flight.arrAir }}</div>
                    </v-col>

                    <v-col cols="12" class="d-block d-md-none text-center py-0">
                      <v-row justify="center">
                        <v-col class="text-center">
                          <v-divider></v-divider>
                          {{ flight.depAir }}
                        </v-col>
                        <v-col class="text-center">
                          <v-divider></v-divider>
                          {{ flight.arrAir }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row class="mt-5 mt-md-0">
                    <v-col class="text-left">
                      Багаж:
                      <v-chip color="light-green accent-4">
                        {{ flight.luggage }}
                      </v-chip>
                      <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2 mr-2"
                        >В наличии:
                        <span class="light-green--text text--accent-4">
                          {{ flight.available }}
                        </span>
                      </span>
                      <v-btn
                        v-if="showDetails || !hasStops"
                        icon
                        @click="showFareRemark(flight.frFlight, variant.Pult)"
                      >
                        <v-icon>la-info</v-icon>
                      </v-btn>
                    </v-col>
                    <!-- <v-col class="text-right">
                      <v-btn icon @click="getTariffs()">
                        <v-icon>mdi-label-percent</v-icon>
                      </v-btn>
                    </v-col> -->

                    <v-col
                      v-if="$vuetify.breakpoint.smAndDown"
                      cols="5"
                      class="text-right pt-4"
                    >
                      <div class="mt-1">
                        <span
                          v-if="!flight.stopsNum"
                          class="light-green--text text--accent-4"
                        >
                          Прямой
                        </span>
                        <span v-else class="red--text">
                          {{
                            flight.stopsNum + " " + stopsTitle(flight.stopsNum)
                          }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-fade-transition>
            <v-divider class="my-2"></v-divider>
          </div>

          <v-row
            no-gutters
            justify="space-between"
            align="center"
            class="pb-2 pt-2 pt-md-0"
          >
            <v-col cols="5" class="d-block d-md-none pb-5">
              В наличии:
              <span class="light-green--text text--accent-4">
                {{ variant | available }}
              </span>
            </v-col>

            <v-col
              cols="7"
              class="text-h5 d-block d-md-none pb-5 text-right"
              style="font-weight: bold"
            >
              {{
                variant.VariantTotal.Text + " " + variant.VariantTotal.Currency
              }}
            </v-col>

            <v-col cols="6" md="6" class="text-left">
              <v-tooltip
                v-if="$vuetify.breakpoint.mdAndUp"
                right
                color="blue-grey lighten-2"
                open-delay="600"
                close-delay="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="hasStops"
                    elevation="0"
                    color="primary"
                    small
                    fab
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleDetails"
                  >
                    <v-icon v-if="showDetails">la-angle-up</v-icon>
                    <v-icon v-else>la-angle-down</v-icon>
                  </v-btn>
                </template>
                <span>Детали полета</span>
              </v-tooltip>
              <v-btn
                v-else-if="hasStops"
                elevation="0"
                color="primary"
                small
                fab
                outlined
                @click="toggleDetails"
              >
                <v-icon v-if="showDetails">la-angle-up</v-icon>
                <v-icon v-else>la-angle-down</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="6" md="6" class="text-right">
              <v-row>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  style="padding-top: 14px"
                  class="pr-0"
                >
                  <div class="text-h5 price">
                    {{
                      variant.VariantTotal.Text +
                      " " +
                      variant.VariantTotal.Currency
                    }}
                  </div>
                </v-col>
                <v-col cols="12" md="4" lg="3" class="pl-0 px-md-5">
                  <v-btn
                    dark
                    elevation="0"
                    color="orange"
                    @click="$emit('Buy', variant)"
                  >
                    Выбрать
                  </v-btn>
                </v-col>
                <!-- <v-col cols="12" md="4" lg="3" class="pl-0 px-md-5">
                  <v-btn
                    dark
                    elevation="0"
                    color="orange"
                    @click="$emit('input', variant)"
                  >
                    Выбрать
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-col>
    <fare-remarks-modal
      v-model="fareRemarkModel"
      :text="fareRemarkText"
      :loading="fareRemarkLoading"
    ></fare-remarks-modal>
  </v-row>
</template>

<script>
import Arrow from "@/components/UI/Arrow.vue";
import ErrorModal from "@/components/UI/ErrorModal.vue";
import ApiCalls from "@/mixins/booking/ApiCalls.js";
import FareRemarksModal from "@/components/FoundedTicketsPage/FareRemarksModal.vue";

export default {
  name: "VariantListItem",
  mixins: [ApiCalls],
  props: {
    colorIndex: {
      type: Number,
      default: 0,
    },
    variant: {
      type: Object,
      require: true,
    },
    passengers: {
      type: Array,
      require: true,
    },
  },
  components: {
    Arrow,
    ErrorModal,
    FareRemarksModal,
  },
  data() {
    return {
      showDetails: false,
      animation: true,
      id: null,
      segments: [],
      fareRemarkModel: false,
      fareRemarkLoading: false,
      fareRemarkText: null,
    };
  },
  mounted() {
    this.id = "var_" + this._uid;
    this.setSegments();
  },
  methods: {
    setSegments() {
      this.segments = this.segments.concat(
        this.variant.Flights.map((f) => ({
          Company: f.CompanyCode,
          Flight: f.Num,
          Departure: { Text: f.Origin.Text },
          Arrival: { Text: f.Destination.Text },
          Date: f.Deptdate.Text,
          Subclass: f.Subclass.Text,
          IsRefundable: f.IsRefundable,
        }))
      );
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
      this.animation = false;
      setTimeout(() => {
        this.animation = true;
        this.$vuetify.goTo("#" + this.id);
      }, 100);
    },
    stopsTitle(num) {
      num = Number(num);
      return num % 100 > 10 && num % 100 < 20
        ? "пересадок"
        : num % 10 == 1
        ? "пересадка"
        : num % 10 > 1 && num % 10 < 5
        ? "пересадки"
        : "пересадок";
    },
    async showFareRemark(flight, pult) {
      this.fareRemarkModel = true;
      if (!this.fareRemarkText) {
        this.fareRemarkLoading = true;
        let req = {
          query: {
            fareremark: {
              company: flight.CompanyCode,
              code: flight.Prices[0].Fare.Remark, //
              answerParams: {
                lang: "ru",
              },
              requestParams: {
                upt: flight.Prices[0].UptXml,
              },
            },
            pult: pult,
          },
        };
        let resp = await this.$axios.post(`/FareRemarks`, req);
        if (!resp.data) return;
        resp.data.forEach((f) => {
          this.fareRemarkText += f;
        });
        this.fareRemarkLoading = false;
      }
    },
  },
  computed: {
    hasStops() {
      return (
        this.variant.SegmentTransferTimes.length < this.variant.Flights.length
      );
    },
    flights() {
      let tmp_flights = [];
      if (!this.showDetails && this.hasStops)
        this.variant.SegmentTransferTimes.forEach((s) => {
          let fs = this.variant.Flights.find(
            (f) => f.Origin.CityCode === s.ISegmentOrig
          );
          let ls = this.variant.Flights.find(
            (f) =>
              f.Destination.CityCode === s.ISegmentDest ||
              f.Destination.Text === s.ISegmentDest
          );
          tmp_flights.push({
            num: fs.Num,
            plane: fs.Airplane,
            company: fs.CompanyName,
            depDate: fs.Deptdate.Text,
            arrDate: ls.Arrvdate.Text,
            depTime: fs.Depttime,
            arrTime: ls.Arrvtime,
            depCity: fs.Origin.City,
            arrCity: ls.Destination.City,
            depAir: fs.Origin.Name + " (" + fs.Origin.Text + ")",
            arrAir: ls.Destination.Name + " (" + ls.Destination.Text + ")",
            flyingTime: s.Text,
            luggage: fs.Prices[0].Baggage ? fs.Prices[0].Baggage : "НЕТ",
            available: fs.Available,
            stopsNum:
              this.variant.Flights.indexOf(ls) -
              this.variant.Flights.indexOf(fs),
          });
        });
      else
        this.variant.Flights.forEach((f) => {
          tmp_flights.push({
            frFlight: f,
            num: f.Num,
            plane: f.Airplane,
            company: f.CompanyName,
            depDate: f.Deptdate.Text,
            arrDate: f.Arrvdate.Text,
            depTime: f.Depttime,
            arrTime: f.Arrvtime,
            depCity: f.Origin.City,
            arrCity: f.Destination.City,
            depAir: f.Origin.Name + " (" + f.Origin.Text + ")",
            arrAir: f.Destination.Name + " (" + f.Destination.Text + ")",
            flyingTime: f.FlightTime,
            luggage: f.Prices[0].Baggage ? f.Prices[0].Baggage : "НЕТ",
            available: f.Available,
          });
        });
      return tmp_flights;
    },
  },
  filters: {
    flyingTime(v) {
      if (!v) return v;
      if (v.indexOf(":") < 0) return v;
      return v.replace(":", "ч ") + "м";
    },
    available(v) {
      let min = v.Flights[0].Available;
      v.Flights.forEach((e) => {
        if (e.Available < min) min = e.Available;
      });
      return min;
    },
  },
};
</script>

<style scoped>
.price {
  display: inline-block;
  font-weight: bold;
}
</style>
