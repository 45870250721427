<template>

  <div class="text-center">
    <v-dialog
        v-model="value"
        width="500"
        persistent
        scrollable
    >
      <v-card>
        <v-card-title class="text-h5 orange white--text">
          Fare remarks
        </v-card-title>

        <v-card-text class="mt-5 text-center" v-if="loading">
          <v-progress-circular color="orange" indeterminate></v-progress-circular>
        </v-card-text>
        <v-card-text v-else class="mt-5" v-html="text">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="$emit('input', false)"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
  name: "FareRemarksModal",
  props: ['text', 'value','loading'],
}
</script>

<style scoped>

</style>
